import { EactdifItemMarkCodeDto } from "@helper/abstraction/eact";
import { DataTableModel } from "@shared/primeng-components/edit-cell-grid/edit-cell-grid.component";

enum FieldWidths {
	FOUR = "4",
	FIVE = "5",
	SIX = "6",
	EIGHT = "8",
	TWELVE = "12",
	FOURTEEN = "14",
	SIXTEEN = "16",
	SEVENTEEN = "17",
	EIGHTTEEN = "18",
	HALF = ".5"
}

const defaultItemMetadata = {
	value: "",
	width: FieldWidths.SIX,
	editable: false,
	objectFieldName: undefined,
	handleMarkCodes: false,
}

// TODO add interface
export interface GridInfoBlock {
	additionalInformation: string;
	markCodeDtoList: EactdifItemMarkCodeDto[];
	fieldCostControlDto: {
		manufacturerOutputPrice: string;
		bulkDiscountRate: string;
	}
}

// Хедеры для таблицы расхождений
export const discrepanciesTableHeaders: DataTableModel[] = [
	{
		headerName: "Расхождение",
		fieldKey: "discrepancies",
		editableCell: false,
		width: FieldWidths.EIGHT + ".5",
	},
	{
		headerName: "Ед.изм.",
		fieldKey: "uom",
		objectFieldName: "name",
		editableCell: false,
		width: FieldWidths.FOUR,
	},
	{
		headerName: "Кол-во",
		fieldKey: "quantityOrdered",
		editableCell: false,
		width: FieldWidths.FIVE,
	},
	{
		headerName: "Цена",
		fieldKey: "priceNet",
		editableCell: false,
		width: FieldWidths.SIX,
	},
	{
		headerName: "Ставка НДС,%",
		fieldKey: "vatRate",
		editableCell: false,
		width: FieldWidths.FIVE,
	},
	{
		headerName: "Стоимость",
		fieldKey: "amountWithoutVat",
		editableCell: false,
		width: FieldWidths.SIX,
	},
	{
		headerName: "Сумма НДС",
		fieldKey: "amountVat",
		editableCell: false,
		width: FieldWidths.SIX,
	},
	{
		headerName: "Стоимость с НДС",
		fieldKey: "amountOrdered",
		editableCell: false,
		width: FieldWidths.SIX,
	},
	{
		headerName: "Коды маркировки",
		fieldKey: "amountOrdered",
		editableCell: false,
		width: FieldWidths.EIGHT,
	},
];

// Хедеры для таблицы
export const headerMetadataTable: DataTableModel[] = [
	{
		headerName: "№",
		fieldKey: "position",
		editableCell: false,
		width: "2",
		isUsedWidth: true,
	},
	{
		headerName: "GTIN",
		fieldKey: "gtin",
		editableCell: false,
		width: "6.5",
		isUsedWidth: true,
	},
	{
		headerName: "Наименование товара",
		fieldKey: "fullName",
		editableCell: false,
		width: FieldWidths.FOURTEEN,
		isUsedWidth: true,
	},
	{
		headerName: "Расхождения",
		fieldKey: "discrepancies",
		editableCell: false,
		width: FieldWidths.EIGHT + ".5"
	},
	{
		headerName: "Ед.изм.",
		fieldKey: "uom",
		objectFieldName: "name",
		editableCell: false,
		width: FieldWidths.FOUR,
	},
	{
		headerName: "Кол-во",
		fieldKey: "quantityOrdered",
		editableCell: false,
		width: FieldWidths.FIVE,
	},
	{
		headerName: "Цена",
		fieldKey: "priceNet",
		editableCell: false,
		width: FieldWidths.SIX,
	},
	{
		headerName: "Ставка НДС,%",
		fieldKey: "vatRate",
		editableCell: false,
		width: FieldWidths.FIVE
	},
	{
		headerName: "Стоимость",
		fieldKey: "amountWithoutVat",
		editableCell: false,
		width: FieldWidths.SIX,
	},
	{
		headerName: "Сумма НДС",
		fieldKey: "amountVat",
		editableCell: false,
		width: FieldWidths.SIX,
	},
	{
		headerName: "Стоимость с НДС",
		fieldKey: "amountOrdered",
		editableCell: false,
		width: FieldWidths.SIX,
	},
];

// Наименования строк и поля подтаблицы в одной товарной позиции
export const subTableFields: DataTableModel[] = [
	{
		headerName: "По документам",
		fieldKey: "discrepancies",
		hideRaw: true,
		fields: {
			uom: {
				...defaultItemMetadata,
				objectFieldName: "name",
				width: FieldWidths.FOUR,
			},
			quantityOrdered: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE,
			},
			priceNet: {
				...defaultItemMetadata,
			},
			vatRate: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE
			},
			amountWithoutVat: {
				...defaultItemMetadata
			},
			amountVat: {
				...defaultItemMetadata
			},
			amountOrdered: {
				...defaultItemMetadata
			},
			orderedList: {
				...defaultItemMetadata,
				handleMarkCodes: true,
				handleMarkCodesMode: "VIEW",
				markList: []
			}
		}
	},
	{
		headerName: "Фактически",
		fieldKey: "discrepancies",
		isRequired: true,
		fields: {
			uom: {
				...defaultItemMetadata,
				objectFieldName: "name",
				width: FieldWidths.FOUR,
			},
			quantityReceived: {
				...defaultItemMetadata,
				editable: true,
				wholeMaxLength: 35,
				decimalMaxLength: 5,
				maxLength: 35,
				width: FieldWidths.FIVE,
			},
			priceFact: {
				...defaultItemMetadata,
				wholeMaxLength: 35,
				decimalMaxLength: 2,
				maxLength: 35,
				editable: true,
			},
			vatRateFact: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE
			},
			amountWithoutVatReceivedFact: {
				...defaultItemMetadata
			},
			amountVatFact: {
				...defaultItemMetadata
			},
			amountReceived: {
				...defaultItemMetadata
			},
			factList: {
				...defaultItemMetadata,
				handleMarkCodes: true,
				handleMarkCodesMode: "EDIT",
				markList: []
			}
		}
	},
	{
		headerName: "Принято",
		fieldKey: "discrepancies",
		isRequired: true,
		fields: {
			uom: {
				...defaultItemMetadata,
				objectFieldName: "name",
				width: FieldWidths.FOUR,
			},
			quantityAccepted: {
				...defaultItemMetadata,
				wholeMaxLength: 35,
				decimalMaxLength: 5,
				maxLength: 35,
				editable: true,
				width: FieldWidths.FIVE,
			},
			priceAccepted: {
				...defaultItemMetadata,
			},
			vatRateAccepted: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE
			},
			amountAcceptedWithoutVat: {
				...defaultItemMetadata
			},
			amountVatAccepted: {
				...defaultItemMetadata
			},
			amountAccepted: {
				...defaultItemMetadata
			}
		}
	},
	{
		headerName: "Не принято",
		fieldKey: "discrepancies",
		isRequired: true,
		fields: {
			uom: {
				...defaultItemMetadata,
				objectFieldName: "name",
				width: FieldWidths.FOUR,
			},
			quantityReturned: {
				...defaultItemMetadata,
				wholeMaxLength: 35,
				decimalMaxLength: 5,
				maxLength: 35,
				editable: true,
				width: FieldWidths.FIVE,
			},
			priceDeliveredNotAccepted: {
				...defaultItemMetadata
			},
			vatRateNotAccepted: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE
			},
			amountReceivedDeliveredNotAcceptedWithoutVat: {
				...defaultItemMetadata
			},
			amountVatNotAccepted: {
				...defaultItemMetadata
			},
			amountReturned: {
				...defaultItemMetadata
			},
			notAcceptedList: {
				...defaultItemMetadata,
				handleMarkCodes: true,
				handleMarkCodesMode: "EDIT",
				markList: []
			}
		}
	},
	{
		headerName: "Брак",
		fieldKey: "discrepancies",
		fields: {
			uom: {
				...defaultItemMetadata,
				objectFieldName: "name",
				width: FieldWidths.FOUR,
			},
			quantityReceivedDefective: {
				...defaultItemMetadata,
				wholeMaxLength: 35,
				decimalMaxLength: 5,
				maxLength: 35,
				editable: true,
				width: FieldWidths.FIVE,
			},
			priceDefective: defaultItemMetadata,
			vatRateDefective: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE
			},
			amountReceivedDefectiveWithoutVat: {
				...defaultItemMetadata
			},
			amountVatDefective: {
				...defaultItemMetadata
			},
			amountReceivedDefective: {
				...defaultItemMetadata
			},
			notAcceptedList: {
				...defaultItemMetadata,
				handleMarkCodes: true,
				handleMarkCodesMode: "EDIT",
				markList: []
			}
		}
	},
	{
		headerName: "Бой",
		fieldKey: "discrepancies",
		fields: {
			uom: {
				...defaultItemMetadata,
				objectFieldName: "name",
				width: FieldWidths.FOUR,
			},
			quantityReceivedBroken: {
				...defaultItemMetadata,
				wholeMaxLength: 35,
				decimalMaxLength: 5,
				maxLength: 35,
				editable: true,
				width: FieldWidths.FIVE,
			},
			priceBroken: defaultItemMetadata,
			vatRateBroken: {
				...defaultItemMetadata,
				editable: false,
				width: FieldWidths.FIVE
			},
			amountReceivedBrokenWithoutVat: {
				...defaultItemMetadata,
				editable: false,
			},
			amountVatBroken: {
				...defaultItemMetadata,
				editable: false,
			},
			amountBroken: {
				...defaultItemMetadata,
				editable: false,
			},
			notAcceptedList: {
				...defaultItemMetadata,
				handleMarkCodes: true,
				handleMarkCodesMode: "EDIT",
				markList: []
			}
		}
	},
	{
		headerName: "Недостача",
		fieldKey: "discrepancies",
		fields: {
			uom: {
				...defaultItemMetadata,
				objectFieldName: "name",
				width: FieldWidths.FOUR,
			},
			quantityReceivedShortage: {
				...defaultItemMetadata,
				wholeMaxLength: 35,
				decimalMaxLength: 5,
				maxLength: 35,
				editable: true,
				width: FieldWidths.FIVE,
			},
			priceShortage: defaultItemMetadata,
			vatRateShortage: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE
			},
			amountReceivedShortageWithoutVat: {
				...defaultItemMetadata
			},
			amountVatShortage: {
				...defaultItemMetadata
			},
			amountReceivedShortage: {
				...defaultItemMetadata
			},
			shortageList: {
				...defaultItemMetadata,
				handleMarkCodes: true,
				handleMarkCodesMode: "EDIT",
				markList: []
			}
		}
	},
	{
		headerName: "Излишек",
		fieldKey: "discrepancies",
		fields: {
			uom: {
				...defaultItemMetadata,
				objectFieldName: "name",
				width: FieldWidths.FOUR,
			},
			quantityReceivedSurplus: {
				...defaultItemMetadata,
				wholeMaxLength: 35,
				decimalMaxLength: 5,
				maxLength: 35,
				editable: true,
				width: FieldWidths.FIVE,
			},
			priceSurplus: defaultItemMetadata,
			noVatRateField: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE
			}, // Вместо Ставка НДС,
			amountReceivedSurplusWithoutVat: {
				...defaultItemMetadata
			},
			noAmountVatField: defaultItemMetadata, // Вместо Сумма НДС
			noAmountField: defaultItemMetadata, // Вместо Стоимость с НДС
			surplusList: {
				...defaultItemMetadata,
				handleMarkCodes: true,
				handleMarkCodesMode: "EDIT",
				markList: []
			}
		}
	}
];

// TODO delete after testing
// Наименования строк и поля таблицы расхождений при добавлении товара
export const subAddTableFields: DataTableModel[] = [
	{
		headerName: "По документам",
		fieldKey: "discrepancies",
		hideRaw: true,
		fields: {
			uom: {
				...defaultItemMetadata,
				objectFieldName: "name",
				width: FieldWidths.FOUR,
			},
			quantityOrdered: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE,
			},
			priceNet: {
				...defaultItemMetadata,
			},
			vatRate: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE,
			},
			amountWithoutVat: {
				...defaultItemMetadata,
			},
			amountVat: {
				...defaultItemMetadata,
			},
			amountOrdered: {
				...defaultItemMetadata,
			},
			orderedList: {
				...defaultItemMetadata,
				handleMarkCodes: true,
				handleMarkCodesMode: "VIEW"
			}
		}
	},
	{
		headerName: "Фактически",
		fieldKey: "discrepancies",
		fields: {
			uom: {
				...defaultItemMetadata,
				objectFieldName: "name",
				width: FieldWidths.FOUR,
			},
			quantityReceived: {
				...defaultItemMetadata,
				wholeMaxLength: 35,
				decimalMaxLength: 5,
				maxLength: 35,
				editable: true,
				width: FieldWidths.FIVE,
			},
			priceFact: {
				...defaultItemMetadata,
				wholeMaxLength: 35,
				decimalMaxLength: 2,
				maxLength: 35,
				editable: true,
			},
			vatRateFact: {
				...defaultItemMetadata,
				editable: true,
				maxLength: 512,
				numberType: "int",
				width: FieldWidths.FIVE,
			},
			amountWithoutVatReceivedFact: {
				...defaultItemMetadata,
			},
			amountVatFact: {
				...defaultItemMetadata,
			},
			amountReceived: {
				...defaultItemMetadata,
			},
			factList: {
				...defaultItemMetadata,
				handleMarkCodes: true,
				handleMarkCodesMode: "EDIT",
				markList: []
			}
		}
	},
	{
		headerName: "Принято",
		fieldKey: "discrepancies",
		fields: {
			uom: {
				...defaultItemMetadata,
				objectFieldName: "name",
				width: FieldWidths.FOUR,
			},
			quantityAccepted: {
				...defaultItemMetadata,
				wholeMaxLength: 35,
				decimalMaxLength: 5,
				maxLength: 35,
				editable: true,
				width: FieldWidths.FIVE,
			},
			priceAccepted: {
				...defaultItemMetadata,
			},
			vatRateAccepted: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE,
			},
			amountAcceptedWithoutVat: {
				...defaultItemMetadata,
			},
			amountVatAccepted: {
				...defaultItemMetadata,
			},
			amountAccepted: {
				...defaultItemMetadata,
			}
		}
	},
	{
		headerName: "Не принято",
		fieldKey: "discrepancies",
		fields: {
			uom: {
				...defaultItemMetadata,
				objectFieldName: "name",
				width: FieldWidths.FOUR,
			},
			quantityReturned: {
				...defaultItemMetadata,
				wholeMaxLength: 35,
				decimalMaxLength: 5,
				maxLength: 35,
				editable: true,
				width: FieldWidths.FIVE,
			},
			priceDeliveredNotAccepted: {
				...defaultItemMetadata,
			},
			vatRateNotAccepted: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE,
			},
			amountReceivedDeliveredNotAcceptedWithoutVat: {
				...defaultItemMetadata,
			},
			amountVatNotAccepted: {
				...defaultItemMetadata,
			},
			amountReturned: {
				...defaultItemMetadata,
			},
			notAcceptedList: {
				...defaultItemMetadata,
				handleMarkCodes: true,
				handleMarkCodesMode: "EDIT",
				markList: []
			}
		}
	},
	{
		headerName: "Брак",
		fieldKey: "discrepancies",
		fields: {
			uom: {
				...defaultItemMetadata,
				objectFieldName: "name",
				width: FieldWidths.FOUR,
			},
			quantityReceivedDefective: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE,
			},
			priceDefective: defaultItemMetadata,
			vatRateDefective: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE,
			},
			amountReceivedDefectiveWithoutVat: {
				...defaultItemMetadata,
			},
			amountVatDefective: {
				...defaultItemMetadata,
			},
			amountReceivedDefective: {
				...defaultItemMetadata,
			},
			notAcceptedList: {
				...defaultItemMetadata,
				handleMarkCodes: true,
				handleMarkCodesMode: "EDIT",
				markList: []
			}
		}
	},
	{
		headerName: "Бой",
		fieldKey: "discrepancies",
		fields: {
			uom: {
				...defaultItemMetadata,
				objectFieldName: "name",
				width: FieldWidths.FOUR,
			},
			quantityReceivedBroken: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE,
			},
			priceBroken: defaultItemMetadata,
			vatRateBroken: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE,
			},
			amountReceivedBrokenWithoutVat: {
				...defaultItemMetadata,
			},
			amountVatBroken: {
				...defaultItemMetadata,
			},
			amountBroken: {
				...defaultItemMetadata,
			},
			notAcceptedList: {
				...defaultItemMetadata,
				handleMarkCodes: true,
				handleMarkCodesMode: "EDIT",
				markList: []
			}
		}
	},
	{
		headerName: "Недостача",
		fieldKey: "discrepancies",
		fields: {
			uom: {
				...defaultItemMetadata,
				objectFieldName: "name",
				width: FieldWidths.FOUR,
			},
			quantityReceivedShortage: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE,
			},
			priceShortage: defaultItemMetadata,
			vatRateShortage: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE,
			},
			amountReceivedShortageWithoutVat: {
				...defaultItemMetadata,
			},
			amountVatShortage: {
				...defaultItemMetadata,
			},
			amountReceivedShortage: {
				...defaultItemMetadata,
			},
			shortageList: {
				...defaultItemMetadata,
				handleMarkCodes: true,
				handleMarkCodesMode: "EDIT",
				markList: []
			}
		}
	},
	{
		headerName: "Излишек",
		fieldKey: "discrepancies",
		fields: {
			uom: {
				...defaultItemMetadata,
				objectFieldName: "name",
				width: FieldWidths.FOUR,
			},
			quantityReceivedSurplus: {
				...defaultItemMetadata,
				wholeMaxLength: 35,
				decimalMaxLength: 5,
				maxLength: 35,
				editable: true,
				width: FieldWidths.FIVE,
			},
			priceSurplus: defaultItemMetadata,
			noVatRateField: {
				...defaultItemMetadata,
				width: FieldWidths.FIVE,
			},
			amountReceivedSurplusWithoutVat: {
				...defaultItemMetadata,
			},
			noAmountVatField: defaultItemMetadata,
			noAmountField: defaultItemMetadata,
			surplusList: {
				...defaultItemMetadata,
				handleMarkCodes: true,
				handleMarkCodesMode: "EDIT",
				markList: []
			}
		}
	}
];

// Заголовки для таблицы итогов
export const headersTotal: DataTableModel[] = [
	{
		headerName: "Итого",
		fieldKey: "total",
		width: "",
	},
	{
		headerName: "Расхождения",
		width: FieldWidths.TWELVE + ".5",
	},
	{
		headerName: "Кол-во",
		width: FieldWidths.SIXTEEN,
	},
	{
		headerName: "Стоимость",
		width: FieldWidths.SIX,
	},
	{
		headerName: "Сумма НДС",
		width: FieldWidths.SIX,
	},
	{
		headerName: "Стоимость с НДС",
		width: FieldWidths.SIX,
	}
];

// Итоги в разрезе расхождений
export const totalData: DataTableModel[] = [
	{
		headerName: "По документам",
		fieldKey: "discrepancies",
		width: FieldWidths.TWELVE + ".5",
		fields: {
			totalQuantityOrdered: {
				...defaultItemMetadata,
				width: FieldWidths.SIXTEEN,
			},
			totalAmountOrderedWithoutVat: {
				...defaultItemMetadata
			},
			totalAmountOrderedVat: {
				...defaultItemMetadata
			},
			totalAmountOrdered: {
				...defaultItemMetadata
			}
		}
	},
	{
		headerName: "Фактически",
		fieldKey: "discrepancies",
		width: FieldWidths.TWELVE + ".5",
		fields: {
			totalQuantityReceived: {
				...defaultItemMetadata,
				width: FieldWidths.SIXTEEN,
			},
			totalAmountReceivedWithoutVat: {
				...defaultItemMetadata
			},
			totalAmountReceivedVat: {
				...defaultItemMetadata
			},
			totalAmountReceived: {
				...defaultItemMetadata
			}
		}
	},
	{
		headerName: "Принято",
		fieldKey: "discrepancies",
		width: FieldWidths.TWELVE + ".5",
		fields: {
			totalQuantityAccepted: {
				...defaultItemMetadata,
				width: FieldWidths.SIXTEEN,
			},
			totalAmountAcceptedWithoutVat: {
				...defaultItemMetadata
			},
			totalAmountAcceptedVat: {
				...defaultItemMetadata
			},
			totalAmountAccepted: {
				...defaultItemMetadata
			}
		}
	},
	{
		headerName: "Не принято",
		fieldKey: "discrepancies",
		width: FieldWidths.TWELVE + ".5",
		fields: {
			totalQuantityReturned: {
				...defaultItemMetadata,
				width: FieldWidths.SIXTEEN,
			},
			totalAmountReturnedWithoutVat: {
				...defaultItemMetadata
			},
			totalAmountReturnedVat: {
				...defaultItemMetadata
			},
			totalAmountReturned: {
				...defaultItemMetadata
			},
		}
	},
	{
		headerName: "Брак",
		fieldKey: "discrepancies",
		width: FieldWidths.TWELVE + ".5",
		fields: {
			totalQuantityReceivedDefective: {
				...defaultItemMetadata,
				width: FieldWidths.SIXTEEN,
			},
			totalAmountDefectiveWithoutVat: {
				...defaultItemMetadata
			},
			totalAmountDefectiveVat: {
				...defaultItemMetadata
			},
			totalAmountDefective: {
				...defaultItemMetadata
			},
		}
	},
	{
		headerName: "Бой",
		fieldKey: "discrepancies",
		width: FieldWidths.TWELVE + ".5",
		fields: {
			totalQuantityReceivedBroken: {
				...defaultItemMetadata,
				width: FieldWidths.SIXTEEN,
			},
			totalAmountBrokenWithoutVat: {
				...defaultItemMetadata
			},
			totalAmountBrokenVat: {
				...defaultItemMetadata
			},
			totalAmountBroken: {
				...defaultItemMetadata
			},
		}
	},
	{
		headerName: "Недостача",
		fieldKey: "discrepancies",
		width: FieldWidths.TWELVE + ".5",
		fields: {
			totalQuantityReceivedShortage: {
				...defaultItemMetadata,
				width: FieldWidths.SIXTEEN,
			},
			totalAmountShortageWithoutVat: {
				...defaultItemMetadata
			},
			totalAmountShortageVat: {
				...defaultItemMetadata
			},
			totalAmountShortage: {
				...defaultItemMetadata
			},
		}
	},
	{
		headerName: "Излишек",
		fieldKey: "discrepancies",
		width: FieldWidths.TWELVE + ".5",
		fields: {
			totalQuantityReceivedSurplus: {
				...defaultItemMetadata,
				width: FieldWidths.SIXTEEN,
			},
			totalAmountSurplusWithoutVat: {
				...defaultItemMetadata
			},
			totalAmountVatReceivedSurplus: {
				...defaultItemMetadata
			},
			noTotalAmountSurplus: {
				...defaultItemMetadata
			},
		}
	},
];

export const infoFields: { [key: string]: string, value: string, title: string }[] = [
	{ key: "codeBySupplier", value: "", title: "Код товара, присвоенный поставщиком" },
	{ key: "codeByBuyer", value: "", title: "Код товара, присвоенный покупателем" },
	{ key: "grossWeight", value: "", title: "Масса груза, т." },
	{ key: "quantityDespatchLu", value: "", title: "Количество груз. мест" },
	{ key: "countryOfOrigin", value: "", title: "Страна производства" },
	{ key: "bestBeforeDate", value: "", title: "Срок реализации" },
	{ key: "addInfo", value: "", title: "Примечание" },
	{ key: "otherInformation", value: "", title: "Дополнительная информация о товаре" },
	{ key: "customCodeOther", value: "", title: "Код по классификатору дополнительной таможенной информации" },
	{ key: "productDate", value: "", title: "Дата производства" },
	{ key: "partyNumber", value: "", title: "Номер партии" },
	{ key: "eactdifItemExtraFieldList", value: "", title: "Доп. поля" },
	{ key: "fieldCostControlDto", value: "", title: "" },
];

export const costControlFields: { [key: string]: string, value: string, title: string }[] = [
	{ key: "manufacturerOutputPrice", value: "", title: "Отпускная цена производителя (импортера)" },
	{ key: "typeDiscount", value: "", title: "Вид скидки" },
	{ key: "bulkDiscountRate", value: "", title: "Оптовая скидка" },
	{ key: "outputPriceIncludingShippingCoast", value: "", title: "Условия доставки товара" },
	{ key: "outputPriceShippingCoast", value: "", title: "Сумма расходов по доставке товаров" },
	{ key: "outputPricePackingCoast", value: "", title: "Сумма расходов по фасовке товаров" },
	{ key: "bulkMarginRate", value: "", title: "Оптовая надбавка" },
	{ key: "cutRate", value: "", title: "Сниженная цена субъекта торговли" },
	{ key: "additionalInformationPrice", value: "", title: "Иные сведения" },
	{ key: "additionalInformation", value: "", title: "Примечание" },
];

export const dependentFields = [
	["quantityReceived", "vatRateFact", "priceFact", "amountWithoutVatReceivedFact", "amountVatFact", "amountReceived"],
	["quantityAccepted", "vatRateAccepted", "priceAccepted", "amountAcceptedWithoutVat", "amountVatAccepted", "amountAccepted"],
	["quantityReturned", "vatRateNotAccepted", "priceDeliveredNotAccepted", "amountReceivedDeliveredNotAcceptedWithoutVat", "amountVatNotAccepted", "amountReturned"],
	["quantityReceivedDefective", "vatRateDefective", "priceDefective", "amountReceivedDefectiveWithoutVat", "amountVatDefective", "amountReceivedDefective"],
	["quantityReceivedBroken", "vatRateBroken", "priceBroken", "amountReceivedBrokenWithoutVat", "amountVatBroken", "amountBroken"],
	["quantityReceivedShortage", "vatRateShortage", "priceShortage", "amountReceivedShortageWithoutVat", "amountVatShortage", "amountReceivedShortage"],
	["quantityReceivedSurplus", "", "priceSurplus", "amountReceivedSurplusWithoutVat", "", ""],
];

export const nonHiddenFields = ["priceFact", "priceAccepted", "priceDeliveredNotAccepted"];
export const emptyFields = [
	"amountWithoutVatReceivedFact", "amountVatFact", "amountReceived",
	"amountAcceptedWithoutVat", "amountVatAccepted", "amountAccepted",
	"amountReceivedDeliveredNotAcceptedWithoutVat", "amountVatNotAccepted", "amountReturned"
];

export const totalEmptyFields = [
	"amountOrdered",
	"amountAccepted",
	"amountReturned",
	"amountReceivedDefective",
	"amountReceived",
	"amountBroken",
	"amountReceivedShortageWithoutVat",
	"amountReceivedSurplusWithoutVat",
	"amountWithoutVatReceivedFact",
	"amountWithoutVat",
	"amountAcceptedWithoutVat",
	"amountReceivedShortageWithoutVat",
	"amountReceivedDeliveredNotAcceptedWithoutVat",
	"amountReceivedBrokenWithoutVat",
	"amountReceivedDefectiveWithoutVat",
	"amountVat",
	"amountVatFact",
	"amountVatAccepted",
	"amountVatNotAccepted",
	"amountVatDefective",
	"amountVatBroken",
	"amountVatShortage",
];

// Данные о прослеживаемости товара (в текущей итерации бэк не отдаёт):
// Количество: ,
// Единица измерения: , Цена:  , Код ТН ВЭД: 
// Доп. четырехзначный код: .
// Cведения по регулированию ценообразования: 
// Отпускная цена, Вид скидки, Оптовая скидка, Условия доставки, Сумма
// расходов по доставке, Сумма расходов по фасовке, Оптовая надбавка,
// Сниженная цена субъекта торговли, Иные сведения, Примечание