import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

import { ConfigurationService } from "@core/configuration.service";
import { CrossauthorizationDTO, CrossauthorizationHeaders, LocationProvider } from "@helper/abstraction/providers";
import { OperationsConfirmByProvider, ProviderConfirmation } from "@helper/abstraction/operations-confirm";

export class Provider {
	public provider = {
		EWAYBILL: {
			get$: (id: string): Observable<OperationsConfirmByProvider> => {
				const url = `${this.config.api.root}/provider/EWAYBILL/${id}`;
				return this.http.get<OperationsConfirmByProvider>(url, { withCredentials: true });
			}
		},

		EINVOICE: {
			get$: (id: string): Observable<ProviderConfirmation> => {
				const url = `${this.config.api.root}/provider/EINVOICE/${id}`;
				return this.http.get<ProviderConfirmation>(url, { withCredentials: true });
			}
		},

		EINVOICEPMT: {
			get$: (id: string): Observable<ProviderConfirmation> => {
				const url = `${this.config.api.root}/provider/EINVOICEPMT/${id}`;
				return this.http.get<ProviderConfirmation>(url, { withCredentials: true });
			}
		},

		TRANSIT: {
			get$: (id: string): Observable<OperationsConfirmByProvider> => {
				const url = `${this.config.api.root}/provider/TRANSIT/${id}`;
				return this.http.get<OperationsConfirmByProvider>(url, { withCredentials: true });
			}
		},

		EDOCUMENT: {
			get$: (id: string): Observable<ProviderConfirmation> => {
				const url = `${this.config.api.root}/provider/EDOCUMENT/${id}`;
				return this.http.get<ProviderConfirmation>(url, { withCredentials: true });
			}
		},
	};

	public providers = {
		ME: {
			crossauthorization$: (location: LocationProvider, headersData: CrossauthorizationHeaders): Observable<CrossauthorizationDTO> => {
				const isPub = location === "PUB";
				// https://a1.api-login.meta-era.by/brel-cabinet/userCabinet/crossauthorization
				// https://api-login.meta-era.by/meta-cabinet/userCabinet/crossauthorization
				const url = `https://${isPub ? "a1." : ""}api-login.meta-era.by/${isPub ? "brel" : "meta"}-cabinet/hs/userCabinet/crossauthorization`;
				const body = { service_type: "CABINET" };

				let headers = new HttpHeaders();
				// headers = headers.set("Content-Type", "application/json"),
				headers = headers.set("Token", headersData.token);
				headers = headers.set("User-Name", headersData.userName);
				return this.http.post<CrossauthorizationDTO>(url, body, { headers });
			}
		}
	}

	constructor(private config: ConfigurationService, private http: HttpClient) { }
}
