import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { catchError, map, takeUntil } from "rxjs/operators";

@Component({
	selector: "app-crypto-mobile",
	templateUrl: "./crypto-mobile.component.html",
	styleUrls: ["./crypto-mobile.component.scss"]
})
export class CryptoMobileComponent implements OnInit, OnDestroy {
	public info = "";
	public callbackCryptoMobile = {
		post$: (params: any): Observable<any> => {
			return this.http.post<any>("api/v1/cryptomobile/oauth/callback", params);
		}
	};
	public signCryptoMobile = {
		post$: (id: number): Observable<any> => {
			return this.http.post<any>(`api/v1/cryptomobile/oauth/sign/${id}`, null);
		}
	};

	private unsubscribe$$ = new Subject<void>();

	constructor(
		private router: Router,
		private changeDetectorRef: ChangeDetectorRef,
		private http: HttpClient,
		private activatedRoute: ActivatedRoute
	) { }

	public ngOnInit(): void {
		const routerArr = this.router.url.split("/");

		if (routerArr[routerArr.length - 2] === "sign") {
			this.signCryptoMobile.post$(+routerArr[routerArr.length - 1]).pipe(
				map(data => {
					this.info = data?.error_description || data?.status;
					window.parent.postMessage(this.info, "*");
					this.changeDetectorRef.detectChanges();
				}),
				catchError((error: HttpErrorResponse): any => {
					this.info = error?.error?.error;
					this.changeDetectorRef.detectChanges();
				}),
				takeUntil(this.unsubscribe$$)
			).subscribe();
		} else {
			const data = this.activatedRoute.snapshot.queryParams;
			this.callbackCryptoMobile.post$(data).pipe(
				map(data => {
					this.info = data?.error_description || data?.status;
					window.parent.postMessage(this.info, "*");
					this.changeDetectorRef.detectChanges();
				}),
				catchError((error: HttpErrorResponse): any => {
					this.info = error?.error?.error;
					this.changeDetectorRef.detectChanges();
				}),
				takeUntil(this.unsubscribe$$)
			).subscribe();
		}
	}

	public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

}
