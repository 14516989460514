import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { UserBackendService } from "@app/user/user-core/services/user-backend.service";
import { DefaultPopupComponent } from "@shared/overlay/default-pop-up.component";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "app-signature-mobile-popup",
	templateUrl: "./signature-mobile-popup.component.html",
	styleUrls: ["./signature-mobile-popup.component.scss"]
})
export class SignatureMobilePopupComponent extends DefaultPopupComponent {
	@Input() public progressUrl = "";
	public showIFrame = true;
	public showMessage?: string;
	public pending = false;
	public isFirst = true;

	private unsubscribe$$ = new Subject<void>();

	constructor(
		private sanitizer: DomSanitizer,
		private userBackendService: UserBackendService,
		private changeDetector: ChangeDetectorRef,
	) {
		super();
		window.addEventListener("message", this.listener);
	}

	public listener = (event: any): any => {
		if (event.data?.hasOwnProperty("method") && this.isFirst) {
			this.openObservable(event.data);
		}
	};

	public get url(): any {
		return this.sanitizer.bypassSecurityTrustResourceUrl(this.progressUrl);
	}

	public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
		window.removeEventListener("message", this.listener);
	}

	private openObservable(data: any): void {
		this.isFirst = false;
		this.showIFrame = false;
		let obs = null;
		if (data?.method === "sign") {
			obs = this.userBackendService.crypto.signCryptoMobile.post$(data?.id);
		}
		if (!obs) {
			throw "no method from service";
		}

		this.pending = true;
		this.changeDetector.detectChanges();
		obs
			.pipe(takeUntil(this.unsubscribe$$))
			.subscribe(value => {
				this.pending = false;
				this.showMessage = value?.error_description ?? value.status;
				this.changeDetector.detectChanges();
				if (value.status === "COMPLETED" || value.status === "CANCELLED") {
					this.close();
				}
			}, () => {
				this.pending = false;
				this.changeDetector.detectChanges();
			});
	}

}
