import { Document, DocumentProperty, DocumentsParams, DocumentType, ExportDocument, DescriptionDocumentType, DocumentKind, DocumentFromAnotherParams, ImportReport, DocumentState, ImportResultFromDataMarkFileDto, DocType, StatusDtoStatusDto } from "@helper/abstraction/documents";
import { Partner, PartnersParams } from "@helper/abstraction/partners";
import { Storage, StoragesParams, TypedStoragesParams } from "@helper/abstraction/storages";
import { createAction } from "@ngrx/store";
import { Draft, SignedDraft, DraftType, SignedDraftDto, DraftDto, SignedDraftSCCrypto } from "@helper/abstraction/draft";
import { HttpErrorResponse } from "@angular/common/http";
import { StatusesParams, Status } from "@helper/abstraction/status";

export const resetDocumentsPage = createAction("[Documents] Reset");

export const downloadSCCrypto = createAction("[Documents] Download SCCrypto");
export const downloadSCCryptoSuccess = createAction(
	"[Documents] Download SCCrypto Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);
export const downloadSCCryptoError = createAction(
	"[Documents] Download SCCrypto Error",
	(payload: HttpErrorResponse | Error): { error: HttpErrorResponse | Error } => ({ error: payload })
);

export const downloadBase64CryptoProxy = createAction("[Documents] Download Base64 SCCrypto");
export const downloadBase64CryptoProxySuccess = createAction(
	"[Documents] Download Base64 SCCrypto Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);
export const downloadBase64CryptoProxyError = createAction(
	"[Documents] Download Base64 SCCrypto Error",
	(payload: HttpErrorResponse | Error): { error: HttpErrorResponse | Error } => ({ error: payload })
);

export const getDocumentsTypesSuccess = createAction(
	"[Documents] Load Documents Types Success",
	(payload: DocumentType[]): { documentTypes: DocumentType[] } => ({ documentTypes: payload })
);

export const switchDocumentType = createAction(
	"[Document] Switch Document Type",
	(documentTypeId: DocumentType, filter?: DocumentsParams, isNotResetDocumentFilter?: boolean): { documentTypeId: DocumentType; filter?: DocumentsParams; isNotResetDocumentFilter?: boolean } => ({ documentTypeId, filter, isNotResetDocumentFilter })
);

export const switchDocumentProperties = createAction(
	"[Document] Switch Document Properties",
	(payload: string): { documentTypeId: string } => ({ documentTypeId: payload })
);

export const setDocumentProperties = createAction(
	"[Documents] Set Documents Properties",
	(payload: DocumentProperty[]): { currentDocumentProperty: DocumentProperty[] } => ({ currentDocumentProperty: payload })
);

export const getDocuments = createAction(
	"[Documents] Get Documents",
	(payload: DocumentsParams): { documentsParams: DocumentsParams } => ({ documentsParams: payload }));

export const getDocumentsSuccess = createAction(
	"[Documents] Get Documents Success",
	(payload: Document[]): { documents: Document[] } => ({ documents: payload })
);

export const getDocumentsError = createAction(
	"[Documents] Get Documents Error",
	(payload: Error): { error: Error } => ({ error: payload })
);

export const stopScrolling = createAction(
	"[Documents] Stop Scrolling",
	(isStop: boolean): { isStop: boolean } => ({ isStop })
);

export const updateDocumentsFilter = createAction(
	"[Documents] Update Documents Filter",
	(filter: DocumentsParams, isNotResetDocumentFilter?: boolean): { filter: DocumentsParams; isNotResetDocumentFilter?: boolean } => ({ filter, isNotResetDocumentFilter })
);

export const resetDocumentFilter = createAction(
	"[Documents] Reset Document Filter",
	(documentTypeId: DocumentType, filter?: DocumentsParams, isNotResetDocumentFilter?: boolean): { documentTypeId: DocumentType; filter?: DocumentsParams; isNotResetDocumentFilter?: boolean } => ({ documentTypeId, filter, isNotResetDocumentFilter })
);

export const resetDocuments = createAction(
	"[Documents] Reset Documents",
	(): { documents: undefined } => ({ documents: undefined })
);

export const selectDocuments = createAction(
	"[Documents] Select Document",
	(payload: Document[]): { selectedDocuments: Document[] } => ({ selectedDocuments: payload })
);

export const resetSelectedDocuments = createAction(
	"[Documents] Reset Select Document",
	(): { selectedDocuments: undefined } => ({ selectedDocuments: undefined })
);


export const updatePartnersFilter = createAction(
	"[Documents] Next Partner Filter",
	(payload: PartnersParams): { filter: PartnersParams } => ({ filter: payload })
);

export const resetPartners = createAction(
	"[Documents] Reset Partners",
	(): { partners: undefined } => ({ partners: undefined })
);

export const getPartners = createAction(
	"[Documents] Get Partners",
	(payload: PartnersParams): { partnerParams: PartnersParams } => ({ partnerParams: payload })
);

export const getPartnersSuccess = createAction(
	"[Documents] Get Partner Success",
	(payload: Partner[]): { partners: Partner[] } => ({ partners: payload })
);

export const getPartnersError = createAction(
	"[Documents] Get Partner Error",
	(payload: Error): { error: Error } => ({ error: payload })
);

export const updateStoragesFilter = createAction(
	"[Documents] Next Storages Filter",
	(payload: TypedStoragesParams): { filter: TypedStoragesParams } => ({ filter: payload })
);

export const resetStorages = createAction(
	"[Documents] Reset Storages",
	(): { storages: undefined } => ({ storages: undefined })
);

export const getStorage = createAction(
	"[Documents] Get Storage",
	(payload: number): { storage: number } => ({ storage: payload })
);

export const getStorageSuccess = createAction(
	"[Documents] Get Storage Success",
	(payload: Storage): { storage: Storage } => ({ storage: payload })
);

export const getStorageError = createAction(
	"[Documents] Get Storage Error",
	(payload: Error): { error: Error } => ({ error: payload })
);

export const getStorages = createAction(
	"[Documents] Get Storages",
	(payload: StoragesParams): { storagesParams: StoragesParams } => ({ storagesParams: payload })
);

export const getStoragesSuccess = createAction(
	"[Documents] Get Storages Success",
	(payload: Storage[]): { storages: Storage[] } => ({ storages: payload })
);

export const getStoragesError = createAction(
	"[Documents] Get Storages Error",
	(payload: Error): { error: Error } => ({ error: payload })
);

export const updateStatusesFilter = createAction(
	"[Documents] Next Statuses Filter",
	(payload: StatusesParams): { filter: StatusesParams } => ({ filter: payload })
);

export const resetStatuses = createAction(
	"[Documents] Reset Statuses",
	(): { statuses: undefined } => ({ statuses: undefined })
);

export const getStatuses = createAction(
	"[Documents] Get Statuses",
	(payload: StatusesParams): { statusesParams: StatusesParams } => ({ statusesParams: payload })
);

export const getStatusesSuccess = createAction(
	"[Documents] Get Statuses Success",
	(payload: Status[]): { statuses: Status[] } => ({ statuses: payload })
);

export const getStatusesError = createAction(
	"[Documents] Get Statuses Error",
	(payload: Error): { error: Error } => ({ error: payload })
);

export const updateTypesFilter = createAction(
	"[Documents] Next Types Filter",
	(payload: DocumentType): { filter: DocumentType } => ({ filter: payload })
);

export const resetTypes = createAction(
	"[Documents] Reset Types",
	(): { types: undefined } => ({ types: undefined })
);

export const getTypes = createAction(
	"[Documents] Get Types",
	(payload: DocumentType): { documentType: DocumentType } => ({ documentType: payload })
);

export const getTypesSuccess = createAction(
	"[Documents] Get Types Success",
	(payload: DocType[]): { types: DocType[] } => ({ types: payload })
);

export const getTypesError = createAction(
	"[Documents] Get Types Error",
	(payload: Error): { error: Error } => ({ error: payload })
);

export const setDescriptionDocumentTypes = createAction(
	"[Documents] Set Description Document Types",
	(payload: DescriptionDocumentType[]): { documentTypes: DescriptionDocumentType[] } => ({ documentTypes: payload })
);

export const openDocument = createAction(
	"[Documents] Open Document",
	(payload: Document | undefined): { document: Document | undefined } => ({ document: payload })
);

export const openSignedDraft = createAction(
	"[Documents] Open Signed Draft",
	(payload: SignedDraft<DocumentKind>): { signedDraft: SignedDraft<DocumentKind> } => ({ signedDraft: payload })
);

export const openSignedDraftSuccess = createAction(
	"[Documents] Open Signed Draft Success"
);

export const openSignedDraftError = createAction(
	"[Documents] Open Signed Draft Error",
	(...payload: Error[]): { errors: Error[] } => ({ errors: payload })
);

export const clearOpenedDraft = createAction(
	"[Documents] Clear Opened Draft"
);

export const getDocument = createAction(
	"[Documents] Get Document",
	(payload: { documentTypeId: string; documentId: number }): { document: { documentTypeId: string; documentId: number } } => ({ document: payload })
);

export const getDocumentSuccess = createAction(
	"[Documents] Get Document Success",
	(payload: Document): { document: Document } => ({ document: payload })
);

export const getDocumentFailed = createAction(
	"[Documents] Get Document Failed",
	(payload: Error): { error: Error } => ({ error: payload })
);

export const pushSelectedItems = createAction(
	"[Documents] Push Select Items",
	(payload: Document[]): { selectedItems: Document[] } => ({ selectedItems: payload })
);

export const resetSelectedItems = createAction("[Documents] Reset Select Items");

export const createXlsx = createAction(
	"[Documents] Create Xlsx",
	(payload: ExportDocument): { export: ExportDocument } => ({ export: payload })
);

export const createXlsxSuccess = createAction("[Documents] Create Xlsx Success");

export const createXlsxError = createAction(
	"[Documents] Create Xlsx Failed",
	(payload: Error): { error: Error } => ({ error: payload })
);

export const createXml = createAction(
	"[Documents] Create Xml",
	(payload: ExportDocument): { export: ExportDocument } => ({ export: payload }));

export const createXmlSuccess = createAction("[Documents] Create Xml Success");

export const createXmlFailed = createAction(
	"[Documents] Create Xml Failed",
	(payload: Error): { error: Error } => ({ error: payload })
);

export const signDraft = createAction(
	"[Documents] Try Sign Draft",
	(payload: Draft<DocumentKind>, isOpenSigned = true, withoutSave = false): { draft: Draft<DocumentKind>; isOpenSigned: boolean; withoutSave: boolean } => ({ draft: payload, isOpenSigned, withoutSave })
);

export const signDocument = createAction(
	"[Documents] Sign Document",
	(payload: string): { documentXml: string } => ({ documentXml: payload })
);

export const signDocumentSuccess = createAction(
	"[Documents] Sign Document Success",
	(payload: SignedDraftDto): { signedDocument: SignedDraftDto } => ({ signedDocument: payload })
);

export const signDocumentError = createAction(
	"[Documents] Sign Document Error",
	(payload: Error): { errors: Error } => ({ errors: payload })
);

export const signDocumentCrypto = createAction(
	"[Documents] Sign Document Crypto",
	(payload: DraftDto): { draftDto: DraftDto } => ({ draftDto: payload })
);

export const signDocumentCryptoSuccess = createAction(
	"[Documents] Sign Document Crypto Success",
	(signedDraft: SignedDraftSCCrypto, id: string): { signedDraft: SignedDraftSCCrypto; id: string } => ({ signedDraft, id })
);

export const signDocumentCryptoError = createAction(
	"[Documents] Sign Document Crypto Error",
	(payload: Error | HttpErrorResponse | string): { errors: Error | HttpErrorResponse | string } => ({ errors: payload })
);

export const signingDraftSuccess = createAction(
	"[Documents] Signing Draft Success",
	(payload: SignedDraft<DocumentKind>, withoutSave = false): { draft: SignedDraft<DocumentKind>; withoutSave: boolean } => ({ draft: payload, withoutSave })
);

export const signingDraftError = createAction(
	"[Documents] Signing Draft Errors",
	(payload: { error: Error; id: string }): { error: Error; id: string } => ({ error: payload.error, id: payload.id })
);

export const createWithValidation = createAction(
	"[Documents] Create Document With Validation",
	(payload: { document: DocumentKind; draftType: DraftType }): { document: DocumentKind; draftType: DraftType } => ({ ...payload })
);

export const createWithValidationNewApi = createAction(
	"[Documents-new] Create Document With Validation",
	(payload: { document: DocumentKind; draftType: DraftType }): { document: DocumentKind; draftType: DraftType } => ({ ...payload })
);

export const ewaybillSaveValidationError = createAction(
	"[Documents] Ewaybill Save Validation Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const einvoiceSaveValidationError = createAction(
	"[Documents] Einvoice Save Validation Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const creatingWithValidationSuccess = createAction(
	"[Documents] Creating Document With Validation Success",
	(payload: Draft<DocumentKind>): { draft: Draft<DocumentKind> } => ({ draft: payload })
);

export const creatingWithValidationError = createAction(
	"[Documents] Creating Document With Validation Errors",
	(payload: Error): { error: Error } => ({ error: payload })
);

export const createDraftThenSign = createAction(
	"[Documents] Create Document Draft Then Sign",
	(payload: { document: DocumentKind; draftType: DraftType }): { document: DocumentKind; draftType: DraftType } => ({ ...payload })
);

export const markAsRead = createAction(
	"[Documents] Mark As Read",
	(ids: number[], docType: DocumentType, isDocumentCheck = false): { ids: number[]; docType: DocumentType; isDocumentCheck: boolean } => ({ ids, docType, isDocumentCheck })
);

export const markAsReadSuccess = createAction(
	"[Documents] Mark As Read Success",
	(isDocumentCheck = false): { isDocumentCheck: boolean } => ({ isDocumentCheck })
);
export const markAsReadError = createAction(
	"[Documents] Mark As Read Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const createDraftThenSignNewApi = createAction(
	"[Documents] Create Documents Draft Then Sign",
	(payload: { document: DocumentKind; draftType: DraftType }): { document: DocumentKind; draftType: DraftType } => ({ ...payload })
);

export const saveSignedDraft = createAction(
	"[Documents] Save Signed Draft",
	<T extends DocumentKind>(payload: SignedDraft<T>): { draft: SignedDraft<T> } => ({ draft: payload })
);

export const saveSignedDraftSuccess = createAction(
	"[Documents] Save Signed Draft Success",
	<T extends DocumentKind>(payload: SignedDraft<T>): { signedDraft: SignedDraft<T> } => ({ signedDraft: payload })
);

export const saveSignedDraftError = createAction(
	"[Documents] Save Signed Draft Error",
	(payload: Error): { error: Error } => ({ error: payload })
);

export const getDraftNumber = createAction(
	"[Documents] Get Ewaybill Draft Number",
	(payload: DraftType): { draftType: DraftType } => ({ draftType: payload })
);

export const getDraftNumberSuccess = createAction(
	"[Documents] Get Ewaybill Draft Number Success",
	(payload: string): { documentDraftNumber: string } => ({ documentDraftNumber: payload })
);

export const getEinvoiceDraftNumber = createAction(
	"[Documents] Get Einvoice Draft Number"
);

export const getEinvoiceDraftNumberSuccess = createAction(
	"[Documents] Get Einvoice Draft Number Success",
	(payload: string): { documentDraftNumber: string } => ({ documentDraftNumber: payload })
);

export const getEdocumentDraftNumber = createAction(
	"[Documents] Get Edocument Draft Number"
);

export const getEdocumentNumberSuccess = createAction(
	"[Documents] Get Edocument Draft Number Success",
	(payload: string): { documentDraftNumber: string } => ({ documentDraftNumber: payload })
);

export const getEactDraftNumber = createAction(
	"[Documents] Get Eact Draft Number"
);

export const getEactDraftNumberSuccess = createAction(
	"[Documents] Get Eact Draft Number Success",
	(payload: string): { documentDraftNumber: string } => ({ documentDraftNumber: payload })
);

export const sendDraft = createAction(
	"[Documents] Send Draft",
	(payload: { draftType: DraftType; draftId: string }): { draftType: DraftType; draftId: string } => ({ ...payload })
);

export const sendDraftSuccess = createAction(
	"[Documents] Send Draft Success"
);

export const sendDraftError = createAction(
	"[Documents] Send Draft Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const createDocumentFromAnother = createAction(
	"[Documents] Create Document From Another",
	(payload: DocumentFromAnotherParams, isVersion?: boolean): { params: DocumentFromAnotherParams, isVersion?: boolean } => ({ params: payload, isVersion })
);

export const createDocumentFromAnotherSuccess = createAction(
	"[Documents] Create Document From Another Success",
	(payload: DocumentKind): { newDocument: DocumentKind } => ({ newDocument: payload })
);

export const createDocumentFromAnotherError = createAction(
	"[Documents] Create Document From Another Error",
	(payload: Error | HttpErrorResponse): { error: Error | HttpErrorResponse } => ({ error: payload })
);

export const setLastOpenedDocument = createAction(
	"[Documents] Set Last Opened Document",
	(payload: Document): { lastOpenedDocument: Document } => ({ lastOpenedDocument: payload })
);

export const importDocument = createAction(
	"[Documents] Import Document",
	(payload: { documentType: DocumentType; file: File; isCopyForFactoring: boolean, isVersion?: boolean }): { documentType: DocumentType; file: File; isCopyForFactoring: boolean, isVersion?: boolean } => ({ ...payload })
);

export const importDocumentSuccess = createAction(
	"[Documents] Import Document Success",
	(report: ImportReport): { report: ImportReport } => ({ report })
);

export const importDocumentError = createAction(
	"[Documents] Import Document Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const importDataMark = createAction(
	"[Documents] Import DataMark",
	(payload: { documentType: DocumentType; file: File, msgType?: string; isVersion?: boolean }): { documentType: DocumentType; file: File, msgType?: string; isVersion?: boolean } => ({ ...payload })
);

export const importDataMarkSuccess = createAction(
	"[Documents] Import DataMark Success",
	(report: ImportResultFromDataMarkFileDto): { report: ImportResultFromDataMarkFileDto } => ({ report })
);

export const importDataMarkError = createAction(
	"[Documents] Import DataMark Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const setOpenedDocumentState = createAction(
	"[Documents] Set Opened Document State",
	(payload: DocumentState): { documentState: DocumentState } => ({ documentState: payload })
);

export const signCryptoDraft = createAction(
	"[Documents] Sign Crypto Success",
	(draftDto: DraftDto, draftType: DraftType): { draftDto: DraftDto; draftType: DraftType } => ({ draftDto, draftType })
);
export const signCryptoDraftSuccess = createAction(
	"[Documents] Sign Crypto Draft Success ",
	(signedDraft: SignedDraftSCCrypto, id: string, draftType: DraftType): { signedDraft: SignedDraftSCCrypto; id: string; draftType: DraftType } => ({ signedDraft, id, draftType })
);
export const signCryptoDraftError = createAction(
	"[Documents] Sign Crypto Draft Error",
	(payload: HttpErrorResponse | string): { error: HttpErrorResponse | string } => ({ error: payload })
);

export const changeHintReportStatus = createAction(
	"[Documents] Change Hint Report Status",
	(hintReport: boolean): { hintReport: boolean } => ({ hintReport })
);

export const showActionsReport = createAction(
	"[Documents] Show Actions Report",
);

export const getDocumentStatus = createAction(
	"[Documents] Get Document Status",
	(id: number, documentType: DocumentType): { id: number, documentType: DocumentType } => ({ id, documentType })
);

export const getDocumentStatusSuccess = createAction(
	"[Documents] Get Document Status Success",
	(status: StatusDtoStatusDto): { status: StatusDtoStatusDto } => ({ status })
);

export const getDocumentStatusError = createAction(
	"[Documents] Get Document Status Error",
	(payload: HttpErrorResponse | string): { error: HttpErrorResponse | string } => ({ error: payload })
);
